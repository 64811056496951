var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "picList-container" }, [
    _c("span", { staticClass: "title" }, [_vm._v("产品图片")]),
    _c(
      "div",
      { staticClass: "mainImage" },
      [
        _c("span", [_vm._v("主图片")]),
        _c(
          "el-upload",
          {
            attrs: {
              action: "/upload/uploadController/uploadOssList",
              "list-type": "picture-card",
              "auto-upload": true,
              headers: _vm.headers,
              "file-list": _vm.list1,
              "before-upload": _vm.beforeAvatarUpload,
              "on-success": _vm.successAvatarUpload,
              disabled: _vm.list1.length > 0 || _vm.disabled,
            },
            scopedSlots: _vm._u([
              {
                key: "file",
                fn: function (ref) {
                  var file = ref.file
                  return _c("div", {}, [
                    _c("img", {
                      staticClass: "el-upload-list__item-thumbnail",
                      attrs: { src: file.url, alt: "" },
                    }),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function ($event) {
                                return _vm.handlePictureCardPreview(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        ),
                        !_vm.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                },
              },
            ]),
          },
          [
            _c("i", {
              staticClass: "el-icon-plus",
              attrs: { slot: "default" },
              slot: "default",
            }),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible, "append-to-body": true },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("img", {
              attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mainImage" },
      [
        _c("span", [_vm._v("列表图片")]),
        _c(
          "el-upload",
          {
            attrs: {
              action: "/upload/uploadController/uploadOssList",
              "list-type": "picture-card",
              "auto-upload": true,
              headers: _vm.headers,
              "file-list": _vm.list2,
              "before-upload": _vm.beforeAvatarUpload2,
              "on-success": _vm.successAvatarUpload2,
              disabled: _vm.disabled,
            },
            scopedSlots: _vm._u([
              {
                key: "file",
                fn: function (ref) {
                  var file = ref.file
                  return _c("div", {}, [
                    _c("img", {
                      staticClass: "el-upload-list__item-thumbnail",
                      attrs: { src: file.url, alt: "" },
                    }),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function ($event) {
                                return _vm.handlePictureCardPreview(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        ),
                        !_vm.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                },
              },
            ]),
          },
          [
            _c("i", {
              staticClass: "el-icon-plus",
              attrs: { slot: "default" },
              slot: "default",
            }),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible, "append-to-body": true },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("img", {
              attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mainImage" },
      [
        _c("span", [_vm._v("详情图片")]),
        _c(
          "el-upload",
          {
            attrs: {
              action: "/upload/uploadController/uploadOssList",
              "list-type": "picture-card",
              "auto-upload": true,
              headers: _vm.headers,
              "file-list": _vm.list3,
              "before-upload": _vm.beforeAvatarUpload2,
              "on-success": _vm.successAvatarUpload3,
              disabled: _vm.disabled,
            },
            scopedSlots: _vm._u([
              {
                key: "file",
                fn: function (ref) {
                  var file = ref.file
                  return _c("div", {}, [
                    _c("img", {
                      staticClass: "el-upload-list__item-thumbnail",
                      attrs: { src: file.url, alt: "" },
                    }),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function ($event) {
                                return _vm.handlePictureCardPreview(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        ),
                        !_vm.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                },
              },
            ]),
          },
          [
            _c("i", {
              staticClass: "el-icon-plus",
              attrs: { slot: "default" },
              slot: "default",
            }),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible, "append-to-body": true },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("img", {
              attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }