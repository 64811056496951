<script>
import tablePageTreeBase from '../../../../components/table_page_tree_base';
import TablePage from './table';

export default {
  extends: tablePageTreeBase,
  components: {
    TablePage,
  },
  data() {
    return {
      placeholder: '请输入搜索的产品系列',
      title: '产品系列',
      restful: '/mdm/baseLazyTreeController/productLevelLazyTree',
    };
  },
};
</script>
