<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import TableForm from './components';
import PicList from './components/picList';
import ProductList from './components/product_list.vue';

formCreate.component('TableForm', TableForm);
formCreate.component('PicList', PicList);
formCreate.component('ProductList', ProductList);
export default {
  extends: Form,
  components: {
    TableForm,
    formCreate: formCreate.$form(),
    PicList,
  },
  data() {
    return {
      rule: [],
      picList: [],
    };
  },

  async created() {
    await this.getFormRule('material_management_add');
    const productLevelName = this.getRule('productLevelName');
    productLevelName.props = {
      ...productLevelName.props,
      params: {
        functionCode: 'select-product-level',
      },
      getValue: (field) => this.getFieldValue(field),
      title: productLevelName.title,
    };
    productLevelName.on.change = (e) => {
      if (e && e.length > 0) {
        this.setValue({
          productLevelName: e[0].productLevelName,
          productLevelCode: e[0].productLevelCode,
        });
      } else {
        this.setValue({
          productLevelName: null,
          productLevelCode: null,
        });
      }
    };

    const barCode = this.getRule('barCode');
    barCode.validate = [
      ...barCode.validate,
      {
        trigger: 'blur',
        pattern: '^[a-zA-Z0-9]*$',
        message: '仅能输入英文和数字的组合',
      },
    ];
    const aiCode = this.getRule('aiCode');
    aiCode.validate = [
      ...aiCode.validate,
      {
        trigger: 'blur',
        pattern: '^[a-zA-Z0-9]*$',
        message: '仅能输入英文和数字的组合',
      },
    ];
    const unitConversion = this.getRule('unitConversion');
    unitConversion.validate = [
      ...unitConversion.validate,
      {
        trigger: 'blur',
        pattern: /^\d+(\.\d+)?$/,
        message: '仅能输入数字',
      },
    ];
    const costPrice = this.getRule('costPrice');
    costPrice.validate = [
      ...costPrice.validate,
      {
        trigger: 'blur',
        pattern: /^\d+(\.\d+)?$/,
        message: '仅能输入数字',
      },
    ];
    const saleCompanyList = this.getRule('saleCompanyList');
    saleCompanyList.value = [];
    saleCompanyList.props.multiple = true;
    if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
      const data = {
        id: this.formConfig.row.id,
      };
      await request.get('/mdm/mdmMaterialController/detail', data)
        .then(async (res) => {
          this.setValue({
            ...res.result,
            caseUnitConversion: res.result.caseUnitConversion ? res.result.caseUnitConversion : 1,
          });
        });
    }
  },
  mounted() {

  },
  methods: {

    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmMaterialController/save';
        let params = formData;

        if (this.formConfig.code === 'edit') {
          url = '/mdm/mdmMaterialController/update';
          params = Object.assign(this.formConfig.row, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },
};
</script>
