<template>
  <div class="picList-container">
    <span class="title">产品图片</span>
    <div class="mainImage">
      <span>主图片</span>
      <el-upload action="/upload/uploadController/uploadOssList" list-type="picture-card" :auto-upload="true" :headers="headers" :file-list="list1" :before-upload="beforeAvatarUpload" :on-success="successAvatarUpload" :disabled="list1.length>0||disabled">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
    <div class="mainImage">
      <span>列表图片</span>
      <el-upload action="/upload/uploadController/uploadOssList" list-type="picture-card" :auto-upload="true" :headers="headers" :file-list="list2" :before-upload="beforeAvatarUpload2" :on-success="successAvatarUpload2" :disabled="disabled">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
    <div class="mainImage">
      <span>详情图片</span>
      <el-upload action="/upload/uploadController/uploadOssList" list-type="picture-card" :auto-upload="true" :headers="headers" :file-list="list3" :before-upload="beforeAvatarUpload2" :on-success="successAvatarUpload3" :disabled="disabled">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import Storage from '../../../../../../utils/storage';

export default {
  name: 'picList',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      headers: {
        loginUserToken: Storage.l.get('TokenKey'),
      },
      list1: [],
      list2: [],
      list3: [],
      allList: [],
    };
  },
  created() {
    // console.log(this.modalConfig);
    console.log(this.value);
  },
  methods: {
    // 移除图片
    handleRemove(file) {
      console.log(file);
      this.$confirm('确认删除该图片吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.allList = this.allList.filter((item) => item.id !== file.id);
        this.$emit('input', this.allList);
        if (file.businessType === 'central') {
          this.list1 = this.list1.filter((item) => item.id !== file.id);
        } else if (file.businessType === 'display') {
          this.list2 = this.list2.filter((item) => item.id !== file.id);
        } else {
          this.list3 = this.list3.filter((item) => item.id !== file.id);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    beforeAvatarUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        this.$message.error('上传图片大小不能超过 3MB!');
        return isLt3M;
      }
      // console.log(this.list1);
      if (this.list1.length > 0) {
        this.$message.error('只能上传一张主图！');
        return false;
      }
    },
    beforeAvatarUpload2(file) {
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        this.$message.error('上传图片大小不能超过 3MB!');
        return isLt3M;
      }
    },
    successAvatarUpload(response, file, fileList) {
      // 添加图片type
      const obj = response.result[0];
      obj.urlAddress = obj.url;
      obj.businessType = 'central';
      this.list1.push(obj);
      this.allList.push(obj);
      this.$emit('input', this.allList);
    },
    successAvatarUpload2(response, file, fileList) {
      const obj = response.result[0];
      obj.urlAddress = obj.url;
      obj.businessType = 'display';
      this.list2.push(obj);
      this.allList.push(obj);
      this.$emit('input', this.allList);
    },
    successAvatarUpload3(response, file, fileList) {
      const obj = response.result[0];
      obj.urlAddress = obj.url;
      obj.businessType = 'detail';
      this.list3.push(obj);
      this.allList.push(obj);
      this.$emit('input', this.allList);
    },
  },
  watch: {
    value(val) {
      console.log(val);
      this.allList = val;
      this.list1 = val.filter((item) => item.businessType === 'central');
      this.list1 = this.list1.map((item) => {
        const i = { ...item };
        i.url = item.urlAddress;
        return i;
      });

      this.list2 = val.filter((item) => item.businessType === 'display');
      this.list2 = this.list2.map((item) => {
        const i = { ...item };
        i.url = item.urlAddress;
        return i;
      });
      this.list3 = val.filter((item) => item.businessType === 'detail');
      this.list3 = this.list3.map((item) => {
        const i = { ...item };
        i.url = item.urlAddress;
        return i;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.picList-container {
  padding-left: 20px;
  .title {
    font-weight: 550;
    margin: 0;
  }
  .mainImage {
  }
  .el-upload-list__item-actions {
    i {
      font-size: 28px;
    }
  }
}
</style>
