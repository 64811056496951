<template>
  <div class="custom-table">
    <div v-if="!disabled">
      <el-button type="text" icon="el-icon-circle-plus-outline" @click="open">添加物料</el-button>
      <el-button type="text" icon="el-icon-upload2">导入</el-button>
    </div>
    <vxe-table border show-overflow ref="xTable" :data="value">
      <vxe-table-column field="materialCode" title="物料编码"></vxe-table-column>
      <vxe-table-column field="materialName" title="物料名称"></vxe-table-column>
      <vxe-table-column field="productLevelName" title="产品层级"></vxe-table-column>
      <vxe-table-column field="specification" title="规格"></vxe-table-column>
      <vxe-table-column v-if="!disabled" title="操作" width="60" fixed="right" align="center">
        <template v-slot="{ row }">
          <div class="setting-btn">
            <Popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(row)"
            >
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </Popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="callBackFn" />
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui';
import SelectConfig from '../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'editTable',
  components: {
    Popconfirm,
    SelectConfig,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disableds: Boolean,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    productType: String,
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        console.log(v);
        // this.value = this.value ? this.value : [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    // 物料选择弹框
    open() {
      // console.log(this.productType);
      // this.value = this.value ? this.value : [];
      // if (!this.value) return;
      const value = this.value ? this.value : [];
      const codes = value.map((item) => item.materialCode);

      let type = 'checkbox';

      if (this.productType && this.productType === 'standard') {
        type = 'radio';
      }

      const params = {
        functionCode: 'mdm_materiel_modal2',
        data: value,
        selectionList: codes,
        idKey: 'materialCode',
        paramData: {
          enableStatus: '009',
        },
        choiceType: type,
        noReset: type === 'radio',
      };
      // console.log(params);
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择回调
    callBackFn(row) {
      const selectData = [];
      if (row) {
        for (const i of row) {
          selectData.push({
            ...i,
            count: 0,
            ratio: 0,
          });
        }
      }
      // console.log(selectData);
      this.$emit('input', selectData);
      this.$emit('change', selectData);
      // if (this.productType && this.productType === 'standard') {
      // }
    },
    // 删除行数据
    removeRow(row) {
      const index = this.$refs.xTable.getRowIndex(row);
      const list = [];
      this.value.forEach((item, k) => {
        if (index !== k) {
          list.push(item);
        }
      });

      this.$emit('input', list);
    },
  },
};
</script>

<style lang="less" scoped>
.custom-table {
  padding-left: 30px;
  box-sizing: border-box;
}
</style>
