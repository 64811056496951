var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "tree-container", attrs: { span: _vm.treeSpan } },
            [
              _c("TablePageTree", {
                ref: "tableTree",
                staticStyle: { paddingRight: "10px" },
                attrs: {
                  title: _vm.title,
                  placeholder: _vm.placeholder,
                  restful: _vm.restful,
                  defaultProps: _vm.defaultProps,
                },
                on: { trigger: _vm.trigger, change: _vm.treeChange },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: _vm.contentSpan } },
            [
              _c(
                "el-scrollbar",
                {
                  staticClass: "tree-style",
                  attrs: {
                    "wrap-class": "tree-scrollbar",
                    native: false,
                    "view-class": "tree-scrollr-container",
                    noresize: false,
                    tag: "div",
                  },
                },
                [
                  _c(_vm.treeTable, {
                    ref: "table",
                    tag: "component",
                    staticClass: "table-container",
                    attrs: { treeCode: _vm.treeCode },
                    on: { resetTrees: _vm.reset },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }