<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import EditTable from '../components';
// import pictureListText from '../components/pictureList_text/pictureList_text.vue';

formCreate.component('editTable', EditTable);
// formCreate.component('pictureListText', pictureListText);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: {},
      formFunctionCode: 'material_group_management_form',
      // materialList: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
    EditTable,
  },
  methods: {
    /**
     * 表单渲染完成后回调
     * */
    formComplete() {
      this.createRule();
      this.getRule('editTable').on.change = (data) => {
        // console.log(data);
        this.setValue({ materialNum: data.length });
      };
    },
    // 获取详情
    async createRule() {
      if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
        request
          .get('/mdm/mdmMaterialGroupController/detail', {
            id: this.formConfig.id,
          })
          .then(async (res2) => {
            // console.log(res2);
            this.setValue({
              ...res2.result,
              editTable: res2.result.materialList,
            });
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        let params = {
          materialList: formData.editTable,
          materialGroupCode: formData.materialGroupCode,
          materialGroupName: formData.materialGroupName,
          description: formData.description,
        };
        let url = '/mdm/mdmMaterialGroupController/save';
        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmMaterialGroupController/update';
          params = { id: this.formConfig.id, ...params };
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
  mounted() {},

};
</script>
