<script>
import TablePage from '../../../../../components/table_page';
import { Form, PositionTable } from '../form';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
    PositionTable,
  },
  data() {
    return {
      // requestUrl: '/mdm/mdmMaterialController/pageList',
      // params: {
      //   code: '',
      // },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('material_management_list');
  },
  methods: {
    beforeModalClick({ val }) {
      // console.log(1, val);
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;

      const { code, name } = val;

      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          code,
          row,
        };
        this.modalConfig.title = name || '查看';
        this.openModal();
      } else if (code === 'position_maintenance') {
        // this.formName = 'PositionTable';
        // this.modalConfig.title = '';
        this.openModal();
      } else {
        // this.openModal();
      }
    },
    disableClick({ val }, urls, text) {
      request.post(urls, [val.id]).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: `${text}成功`,
          });
          this.getList();
        }
      });
    },
    deleteClick({ val }) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
      } else {
        console.log(1);
        datas = [val.id];
      }
      request.post('/mdm/mdmMaterialController/delete', datas).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getList();
        }
      });
    },
  },
};
</script>
