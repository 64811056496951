<script>
import TablePage from '../../../../../../components/table_page';
// import configs from '../../data';
import Modal from '../../../../../../components/modal';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmPositionController/findPositionUserOrgPageList',
      params: {
        code: '',
      },
      // configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  props: ['codes'],
  watch: {
    codes(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('current_organization_1');
  },
  methods: {
    // modalClick({ val, row }) {
    // this.formName = 'Form';
    // console.log(val);
    // this.formConfig = {};
    // let urls = '';
    // let text = '';
    // if (val.code === 'edit') {
    //   this.formConfig = {
    //     ...val,
    //     ...row,
    //   };
    //   this.modalConfig.title = '编辑';
    //   this.openModal();
    // } else if (val.code === 'organization_delete') {
    //   this.deleteClick({ val: row });
    // } else if (val.code === 'enable') {
    //   urls = '/mdm/mdmOrgController/enable';
    //   text = '启用';
    //   this.disableClick({ val: row }, urls, text);
    // } else if (val.code === 'disable') {
    //   urls = '/mdm/mdmOrgController/disable';
    //   text = '禁用';
    //   this.disableClick({ val: row }, urls, text);
    // } else if (val.code === 'position_maintenance') {
    //   this.formName = 'PositionTable';
    //   this.modalConfig.title = '';
    //   this.openFull();
    // } else {
    //   this.openModal();
    // }
    // },
    // disableClick({ val }, urls, text) {
    //   request.post(urls, [val.id])
    //     .then((res) => {
    //       if (res.success) {
    //         this.$message({
    //           type: 'success',
    //           message: `${text}成功`,
    //         });
    //         this.getList();
    //       }
    //     });
    // },
    // deleteClick({ val }) {
    //   console.log(val);
    //   let datas = [];
    //   if (this.selection.length > 0) {
    //     datas = this.selection;
    //   } else {
    //     console.log(1);
    //     datas = [val.id];
    //   }
    //   request.post('/mdm/mdmOrgController/delete', datas)
    //     .then((res) => {
    //       if (res.success) {
    //         this.$message({
    //           type: 'success',
    //           message: '删除成功',
    //         });
    //         this.getList();
    //       }
    //     });
    // },
  },
};
</script>
