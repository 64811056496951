<script>
import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
// import request from '../../../../../utils/request';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmMaterialGroupController/pageList',
      params: {
        code: '',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  // props: {
  //   treeCode: String,
  // },
  watch: {
    // treeCode(val) {
    //   this.params.code = val;
    //   this.getList();
    // },
  },
  created() {
    this.getConfigList('material_group_management_list');
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      // console.log(val, row);
      if (!this.beforeModalClick({ val })) return;
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          type: val.code,
          code: 'add',
          buttonCode: 'add',
        };
        this.openFull();
      } else if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          code: 'view',
          buttonCode: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      } else {
        this.formConfig = {
          type: val.code,
          code: 'edit',
          buttonCode: 'edit',
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      }
    },
  },
};
</script>
