var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      !_vm.disabled
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.open },
                },
                [_vm._v("添加物料")]
              ),
              _c(
                "el-button",
                { attrs: { type: "text", icon: "el-icon-upload2" } },
                [_vm._v("导入")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.value },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "materialCode", title: "物料编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "materialName", title: "物料名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "productLevelName", title: "产品层级" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "specification", title: "规格" },
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: {
                  title: "操作",
                  width: "60",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            { staticClass: "setting-btn" },
                            [
                              _c(
                                "Popconfirm",
                                {
                                  attrs: {
                                    title: "此操作将永久删除该数据？",
                                    "confirm-button-type": "text",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.removeRow(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { color: "#f5222d" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2423843466
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.callBackFn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }